import { useEffect, useState } from "react";
import "./search.css";
import axios from "axios";
import CryptoJS from "crypto-js";
import Box from "@mui/material/Box";
import { IoMdClose } from "react-icons/io";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Loading from "../loading/Loading";

const Search = ({ closeView }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setStore] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const secret = process.env.REACT_APP_SECRET;
  const payload = {
    iss: process.env.REACT_APP_SECRET_ISS,
    aud: process.env.REACT_APP_SECRET_AUD,
  };
  const header = {
    alg: process.env.REACT_APP_SECRET_ALG,
  };
  const encodeHeader = btoa(JSON.stringify(header));
  const payloadString = btoa(JSON.stringify(payload));
  const signature = CryptoJS.HmacSHA256(
    encodeHeader + "." + payloadString,
    secret
  ).toString(CryptoJS.enc.Base64);
  const jwt = `${encodeHeader}.${payloadString}.${signature}`;
  const api = process.env.REACT_APP_API_SEARCH_A;
  const dataenv = process.env.REACT_APP_DATA;

  useEffect(() => {
    const product_views = () => {
      setIsLoading(true); // Show loading indicator
      axios
        .get(`https:${api}=${searchTerm}`, {
          headers: {
            "Content-Type": `${dataenv}`,
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          setStore(response.data);
          setIsLoading(false); // Hide loading indicator
        })
        .catch((error) => {
          // console.error("Error posting data:", error);
          setIsLoading(false); // Hide loading indicator even if there's an error
        });
    };

    if (searchTerm !== "") {
      setStore([]); // Clear previous results
      product_views();
    } else {
      setStore([]);
    }

    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleLinkClick = (productId, catalog, table) => {
    closeView();
    if (catalog === null) {
      navigate(`/product/${productId}`);
    } else {
      navigate(`/productsingle/${productId}?sizes=${catalog}/viewpro.php`);
    }
  };

  return (
    <>
      <div className="view_parent"></div>

      <div className="search_area_container">
        <div className="search_area_sub_container">
          <div className="searchpage_input_container">
            <div className="search_input_divpart">
              <Box component="form" noValidate autoComplete="off">
                <TextField
                  id="outlined-basic"
                  label="Search Product"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search_input"
                />
              </Box>
            </div>
            <div onClick={closeView} className="search_close_button">
              <IoMdClose />
            </div>
          </div>
          <div className="searchpage_product">
            {isLoading ? (
              <p>
                <Loading />
              </p> // Display loading message
            ) : (
              searchResults.map((item) => (
                <div key={item.id} className="list_product">
                  <div
                    className="list_item"
                    onClick={() =>
                      handleLinkClick(item.id, item.catalog, item.table_name)
                    }
                  >
                    <div className="searchpage_img">
                      <img
                        src={
                          item.catalog
                            ? `${process.env.REACT_APP_API_IMAGE}${item.thumnail}`
                            : `${process.env.REACT_APP_API_IMAGE_SECOND}${item.thumnail}`
                        }
                        alt=""
                        width="100%"
                        height="100%"
                      />
                    </div>
                    <div className="view_pro_detail">
                      <div className="pro_name">
                        <p>{item.name}</p>
                      </div>
                      <div className="pro_price">
                        <p>&#8377; {item.discount}/-</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
