import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { useUser } from "../../stateManagement/UserContext";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import SwiperCore from "swiper/core";
import { Navigation } from "swiper/modules";
import { Paper, IconButton, Collapse } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { RxFontSize } from "react-icons/rx";
import paymentcard from "../../Component/footer/product-payment (5).webp";
import { FaRegHeart } from "react-icons/fa6";

import sale from "../home/images/Sale.png";
import img1 from "../home/images/img (1).png";
import img2 from "../home/images/img (2).png";
import img3 from "../home/images/img (3).png";
import img4 from "../home/images/img (4).png";
import img5 from "../home/images/img (5).png";
import img6 from "../home/images/img (6).png";
import dupimg from "../home/images/Dupatta.png";
import setsimg from "../home/images/IMG_9720.png";
import kurtiimg from "../home/images/IMG_9412 (1).png";

import { useLocation } from "react-router-dom";
import "../product/productsingle.css";
import "../product/product.css";
import Sizechart from "../../Component/size/Sizechart";
import Color from "../../Component/colors/Color";
import Loading from "../../Component/loading/Loading";

SwiperCore.use([Navigation]);

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
}

const Singlepage = () => {
  const { productId } = useParams();
  const { user } = useUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sizes = queryParams.get("sizes");
  const [productlike, setProductlike] = useState([]);
  const [statusmay, setStatusmay] = useState(true);
  const [sizeselect, setSizeSelect] = useState("sno");
  const [priceExpanded, setPriceExpanded] = useState(false);
  const [disclaimerExpanded, setDisclaimerExpanded] = useState(false);
  const [shippingExpanded, setShippingExpanded] = useState(false);
  const [size, setSize] = useState(false);
  const [color, setColor] = useState(false);
  // eslint-disable-next-line
  const [colorCode, setColorCode] = useState("");
  const [customcolor, setCustomcolor] = useState(false);
  // eslint-disable-next-line
  const [colormessage, setColorMessage] = useState(false);

  // eslint-disable-next-line
  const [currentDate, setCurrentDate] = useState(getDate());
  const [wishData, setWishData] = useState({});

  const [AddData, setAddData] = useState({});

  const [postData, setPostData] = useState({
    date: currentDate,
    product_details: {},
    user_details: {},
  });

  useEffect(() => {
    if (user) {
      setAddData({
        ...AddData,
        user_id: user.user_id,
        size: sizeselect,
      });
    }
    // eslint-disable-next-line
  }, [sizeselect]);

  useEffect(() => {
    if (user) {
      setWishData({
        ...wishData,
        user_id: user.user_id,
        size: sizeselect,
      });
    }
    // eslint-disable-next-line
  }, [sizeselect]);

  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        setPostData((prevState) => ({
          ...prevState,
          customer_ip: response.data.ip, // Set customer_ip to the fetched IP address
        }));
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  }, []);

  useEffect(() => {
    if (user) {
      setPostData({
        date: currentDate,
        user_details: user,
        email: user.user_email,
      });
    }
    // eslint-disable-next-line
  }, []);

  const handlePriceToggle = () => {
    setPriceExpanded((prevExpanded) => !prevExpanded);
  };

  const handleshippingToggle = () => {
    setShippingExpanded((prevExpanded) => !prevExpanded);
  };

  const handledisclaimerToggle = () => {
    setDisclaimerExpanded((prevExpanded) => !prevExpanded);
  };

  const [store, setstore] = useState();

  const secret = process.env.REACT_APP_SECRET;
  const payload = {
    iss: process.env.REACT_APP_SECRET_ISS,
    aud: process.env.REACT_APP_SECRET_AUD,
  };
  const header = {
    alg: process.env.REACT_APP_SECRET_ALG,
  };
  const encodeHeader = btoa(JSON.stringify(header));
  const payloadString = btoa(JSON.stringify(payload));
  const signature = CryptoJS.HmacSHA256(
    encodeHeader + "." + payloadString,
    secret
  ).toString(CryptoJS.enc.Base64);
  const jwt = `${encodeHeader}.${payloadString}.${signature}`;
  const api = process.env.REACT_APP_API_CATALOG_A;
  const dataenv = process.env.REACT_APP_DATA;
  const product_views = () => {
    axios
      .get(`https:${api}${sizes}?id=${productId}`, {
        headers: {
          "Content-Type": `${dataenv}`,
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        setstore(response.data);
        setPostData((prevState) => ({
          ...prevState,
          product_details: response.data, // Set customer_ip to the fetched IP address
        }));
        setAddData((prevState) => ({
          ...prevState,
          product_details: response.data,
          product_id: response.data.unique_id,
        }));
        setWishData((prevState) => ({
          ...prevState,
          product_details: response.data,
          product_id: response.data.unique_id,
          catalog: response.data.catalog,
        }));
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  useEffect(() => {
    product_views();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const maylikeview = process.env.REACT_APP_API_SLIDER;

  const MayLike_views = () => {
    axios
      .get(`https:${maylikeview}`, {
        headers: {
          "Content-Type": `${dataenv}`,
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        setProductlike(response.data);
        setStatusmay(false);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  useEffect(() => {
    MayLike_views();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [swiper, setSwiper] = useState(null);

  const handleThumbnailClick = (index) => {
    if (swiper !== null) {
      swiper.slideTo(index);
    }
  };

  // product searching part

  if (!store || store.message === "No data found for the specified ID.") {
    return (
      <div className="notfound_data">
        <div className="success_wrap">
          <p className="success_p"> Not Found the data </p>
          <div>
            <button className="success_link"></button>
          </div>
        </div>
      </div>
    );
  }

  // collection data part
  const data = [
    {
      img1: sale,
      head: "Sale",
      link: "sales",
    },
    {
      img1: img1,
      head: "Exclusive sets",
      link: "exclusive",
    },
    {
      img1: img2,
      head: "All whites",
      link: "allwhite",
    },
    {
      img1: img3,
      head: "Sarees",
      link: "sarees",
    },
    {
      img1: img4,
      head: "Short Tops",
      link: "short",
    },
    {
      img1: img5,
      head: "Bottom",
      link: "bottom",
    },
    {
      img1: img6,
      head: "Premium Unstitched",
      link: "premium",
    },
    {
      img1: kurtiimg,
      head: "Kurti",
      link: "kurti",
    },
    {
      img1: dupimg,
      head: "Dupatta",
      link: "dupatta",
    },
    {
      img1: setsimg,
      head: "Sets",
      link: "sets",
    },
  ];

  const sizeChange = (e, size) => {
    e.preventDefault();
    setSizeSelect(size);
  };

  const buylogin = () => {
    toast.error("please login account");
  };

  const closeViewSize = () => {
    setColor(false);
    setSize(false);
    document.body.style.overflow = "auto";
  };

  const orderreact = process.env.REACT_APP_API_CATALOGORDER_A;

  const order_accept = (event) => {
    event.preventDefault();
    if (user) {
      axios
        .post(`https:${orderreact}`, postData, {
          headers: {
            "Content-Type": `${dataenv}`,
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          setColorMessage(true);
          toast.success("successfully order");
          toast.success("order confirm");
        })
        .catch((error) => {
          console.error("Error posting data:", error);
        });
    } else {
      alert("Please Login");
    }
  };

  const carttoadd = process.env.REACT_APP_API_PRODUCT_C;

  const Addtocart1 = (e) => {
    e.preventDefault();
    if (user) {
      axios
        .post(`https:/${carttoadd}`, AddData, {
          headers: {
            "Content-Type": `${dataenv}`,
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          if (response.data.message === "Product added to cart successfully.") {
            toast.success("Addtocart");
          }
        })
        .catch((error) => {
          console.error("Error posting data:", error);
        });
    } else {
      toast.error("you are not login");
    }
  };

  const cartowish = process.env.REACT_APP_API_PRODUCT_D;
  const wish_cart1 = (e, productid) => {
    e.preventDefault();
    if (user) {
      axios
        .post(`http${cartowish}`, wishData, {
          headers: {
            "Content-Type": `${dataenv}`,
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          if (
            response.data.message === "wishlist added to cart successfully."
          ) {
            toast.success("wishtocart");
          } else if (
            response.data.message === "This product is already in your cart."
          ) {
            toast.error("this product already in wishlist");
          }
        })
        .catch((error) => {
          console.error("Error posting data:", error);
        });
    } else {
      toast.error("you are not login");
    }
  };
  if (statusmay) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <div className="collection-item">
        <div className="collection-item_home">
          {data.map((item, index) => (
            <div key={index} className="collection-item-single">
              <Link to={`/${item.link}`}>
                <div className="collection-item_img">
                  <img src={item.img1} alt="" width="100%" height="100%" />
                </div>
                <div className="collection-item_head">
                  <p>{item.head}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      {/* navigate part */}
      <div className="singlepage_product_pwd">
        <div className="pwd_content_product">
          <p>
            <span>
              <Link to="/">Home </Link>
            </span>
            /{" "}
            <span>
              <Link to="/">Product</Link>
            </span>{" "}
            / {store.name}
          </p>
        </div>
      </div>

      {/* product show part */}
      <div className="singlepage_product_boxcontainer">
        {/* images product*/}
        <div className="singlepage_product_images">
          <div className="singlepage_product_fullimage">
            <Swiper navigation={true} onSwiper={setSwiper} className="mySwiper">
              {store.collection_image &&
                Array.isArray(store.collection_image) &&
                store.collection_image.length > 0 &&
                store.collection_image.map((item, index) => (
                  <SwiperSlide className="myimageSwiper" key={index}>
                    <img
                      src={`${process.env.REACT_APP_API_IMAGE}${item}`}
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </SwiperSlide>
                ))}
              <SwiperSlide className="myimageSwiper">
                <img
                  src={`${process.env.REACT_APP_API_IMAGE}${store.thumnail}`}
                  alt=""
                  width="100%"
                  height="100%"
                />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="singlepage_product_smallimage">
            {store.collection_image &&
              Array.isArray(store.collection_image) &&
              store.collection_image.length > 0 &&
              store.collection_image.map((item, index) => (
                <div
                  onClick={() => handleThumbnailClick(index)}
                  className="singlepage_product_boximagesmall"
                >
                  <img
                    src={`${process.env.REACT_APP_API_IMAGE}${item}`}
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </div>
              ))}
            <div
              onClick={() =>
                handleThumbnailClick(store.collection_image.length)
              }
              className="singlepage_product_boximagesmall"
            >
              <img
                src={`${process.env.REACT_APP_API_IMAGE}${store.thumnail}`}
                alt=""
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>

        {/* details product*/}
        <div className="singlepage_product_details">
          <div className="singlepage_product_name">
            <p>{store.name}</p>
          </div>
          <div className="singlepage_product_discount">
            <p>
            {Math.round(((store.price - store.discount) / store.price) * 100)}%
            </p>
          </div>
          <div className="singlepage_product_price">
            <div className="singlepage_product_price_original">
              <p>Rs. {store.discount}/-</p>
            </div>
            <div className="singlepage_product_price_dscountprice">
              <del>
                <p>RS: {store.price}/-</p>
              </del>
            </div>
          </div>

          <div className="singlepage_product_material">
            <ul>
              <li>Color : {store.color}</li>
              <li>Material :{store.Material}</li>
              <li>Length of Chikan Kurti:{store.length}</li>
              <li>Touch & Feel: Soft and comfortable Chikankari Kurti</li>
              <li>Thread: Cotton thread</li>
            </ul>
          </div>
          {store.catalog !== "dupatta" &&
            store.catalog !== "premium" &&
            store.catalog !== "sarees" && (
              <div className="singlepage_product_size">
                <div className="singlepage_product_size_head">
                  <p>Size:</p>
                </div>
                <div className="singlepage_product_size_option">
                  <div
                    className={`singlepage_product_size_option_box ${
                      sizeselect === "xsno" && "selected_size"
                    }`}
                    onClick={(e) => sizeChange(e, "xsno")}
                  >
                    <p>XS</p>
                  </div>
                  <div
                    className={`singlepage_product_size_option_box ${
                      sizeselect === "sno" && "selected_size"
                    }`}
                    onClick={(e) => sizeChange(e, "sno")}
                  >
                    <p>S</p>
                  </div>
                  <div
                    className={`singlepage_product_size_option_box ${
                      sizeselect === "mno" && "selected_size"
                    }`}
                    onClick={(e) => sizeChange(e, "mno")}
                  >
                    <p>M</p>
                  </div>
                  <div
                    className={`singlepage_product_size_option_box ${
                      sizeselect === "lno" && "selected_size"
                    }`}
                    onClick={(e) => sizeChange(e, "lno")}
                  >
                    <p>L</p>
                  </div>
                  <div
                    className={`singlepage_product_size_option_box ${
                      sizeselect === "xlno" && "selected_size"
                    }`}
                    onClick={(e) => sizeChange(e, "xlno")}
                  >
                    <p>XL</p>
                  </div>
                  <div
                    className={`singlepage_product_size_option_box ${
                      sizeselect === "xxlno" && "selected_size"
                    }`}
                    onClick={(e) => sizeChange(e, "xxlno")}
                  >
                    <p>XXL</p>
                  </div>
                  <div
                    className={`singlepage_product_size_option_box ${
                      sizeselect === "xxxlno" && "selected_size"
                    }`}
                    onClick={(e) => sizeChange(e, "xxxlno")}
                  >
                    <p>XXXL</p>
                  </div>
                </div>
              </div>
            )}

          <div className="singlepage_product_questions">
            {store.catalog !== "dupatta" &&
              store.catalog !== "premium" &&
              store.catalog !== "sarees" && (
                <div
                  className="singlepage_product_question_box"
                  onClick={() => setSize(true)}
                >
                  <div className="singlepage_product_question_icon1">
                    <p>
                      <RxFontSize />
                    </p>
                  </div>
                  <div className="singlepage_product_question_name">
                    <p>Size Guide</p>
                  </div>
                </div>
              )}
            {(store.customcolor === "yes" ||
              store.customcolor === "Yes" ||
              store.customcolor === "YES") && (
              <div>
                <div
                  className="singlepage_product_question_box"
                  onClick={() => setColor(true)}
                >
                  <div className="singlepage_product_question_icon1">
                    <p>
                      <RxFontSize />
                    </p>
                  </div>
                  <div className="singlepage_product_question_name">
                    <p>Color chart</p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {(store.customcolor === "yes" ||
            store.customcolor === "Yes" ||
            store.customcolor === "YES") && (
            <>
              <div className="checkbox_color">
                <input
                  type="checkbox"
                  name="Custom"
                  onClick={() => {
                    setCustomcolor(!customcolor);
                  }}
                />
                <span> Custom Color </span>
                <div className="checkbox_color_message">
                  {customcolor && (
                    <div>
                      <p>
                        Enter the Color Code for the special color below. Please
                        refer to the above color chart to find the appropriate
                        color code.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          {(store.customcolor === "yes" ||
            store.customcolor === "Yes" ||
            store.customcolor === "YES") &&
            customcolor && (
              <>
                <hr className="line_product" />
                <div className="singlepage_product_pincode color_box">
                  <div className="singlepage_product_pincode_name">
                    <p>| Special Color Request</p>
                  </div>
                  <div>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      className="singlepage_product_box_location_check"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Enter color code"
                        variant="outlined"
                        value={postData.custom_color}
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            custom_color: e.target.value,
                          })
                        }
                        required
                      />
                      <Button
                        variant="contained"
                        className="singlepage_product_button_search"
                        onClick={order_accept}
                      >
                        Send
                      </Button>
                    </Box>
                  </div>
                </div>
                {colormessage && (
                  <>
                    <div className="color_message">
                      <p>
                        Your color code ( {postData.custom_color} ) has been
                        selected. You can now check out this product with the
                        selected color.
                      </p>
                    </div>
                  </>
                )}
              </>
            )}

          <hr className="line_product" />

          <div className="singlepage_product_addtocard">
            {parseInt(store[sizeselect]) > 0 ? (
              user ? (
                <>
                  <div
                    className="singlepage_product_addtocard_button"
                    onClick={(e) => {
                      Addtocart1(e, store.unique_id);
                    }}
                  >
                    <p>Add to cart </p>
                  </div>
                  <div
                    className="singlepage_product_wishlist_icon"
                    onClick={(e) => {
                      wish_cart1(e, store.unique_id);
                    }}
                  >
                    <p>
                      <FaRegHeart />
                    </p>
                  </div>
                </>
              ) : (
                <div onClick={buylogin} className="singlepage_product_addtocard_button">
                  <p> Add to Cart </p>
                </div>
              )
            ) : (
              <div>
                <p></p>
              </div>
            )}
          </div>

          <div className="singlepage_product_buynow">
            {parseInt(store[sizeselect]) > 0 ? (
              user ? (
                <Link
                  to={`/buy/${productId}?size=${sizeselect}C&apicall=${sizes}`}
                >
                  <p>Buy it now</p>
                </Link>
              ) : (
                <div onClick={buylogin}>
                  <p>Buy it now</p>
                </div>
              )
            ) : (
              <div>
                <p>NOT PRESENT</p>
              </div>
            )}
          </div>

          <div className="singlepage_product_payment_guarantee">
            <div className="singlepage_product_payment_guarantee_name">
              <p>Guarantee Safe Checkout</p> <hr />
            </div>
            <div className="singlepage_product_payment_guarntee_img">
              <img src={paymentcard} alt="" width="100%" />
            </div>
          </div>

          <div className="singlepage_product_descrption">
            <Paper
              style={{
                background: "transparent",
                width: "80%",
                marginLeft: "25px",
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <div className="singlepage_product_descrptionbox">
                <div className="singlepage_product_descrptionbox_name">
                  <p>Description</p>
                </div>
                <IconButton onClick={handlePriceToggle}>
                  {priceExpanded ? <Remove /> : <Add />}
                </IconButton>
              </div>

              <Collapse in={priceExpanded}>
                <div style={{ padding: "16px" }}>
                  <p>{store.description}</p>
                </div>
              </Collapse>
            </Paper>
          </div>
          <div className="singlepage_product_shipping">
            <Paper
              style={{
                background: "transparent",
                width: "80%",
                marginLeft: "25px",
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <div className="singlepage_product_descrptionbox">
                <div className="singlepage_product_descrptionbox_name">
                  <p>Shipping & Return </p>
                </div>
                <IconButton onClick={handleshippingToggle}>
                  {shippingExpanded ? <Remove /> : <Add />}
                </IconButton>
              </div>

              <Collapse in={shippingExpanded}>
                <div
                  style={{ padding: "16px" }}
                  className="singlepage_product_descrptionbox_para"
                >
                  <p>
                    We provide Free Shipping Across India on Prepaid orders with
                    an additional 5% discount & 50Rs. is chargeable on Cash on
                    Delivery Shipments.We will give you a tracking number once
                    your order has been shipped. Click here for additional
                    details.We'd be glad to exchange the item that is
                    unworn/unused with the original tags, within 3days from the
                    date of delivery. Before initiating a request for an
                    exchange, you must read the eligibility criteria for
                    exchange process. For further details, click this link.
                  </p>
                </div>
              </Collapse>
            </Paper>
          </div>
          <div className="singlepage_product_disclaimer">
            <Paper
              style={{
                background: "transparent",
                width: "80%",
                marginLeft: "25px",
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <div className="singlepage_product_descrptionbox">
                <div className="singlepage_product_descrptionbox_name">
                  <p>Disclaimer</p>
                </div>
                <IconButton onClick={handledisclaimerToggle}>
                  {disclaimerExpanded ? <Remove /> : <Add />}
                </IconButton>
              </div>

              <Collapse in={disclaimerExpanded}>
                <div
                  style={{ padding: "16px" }}
                  className="singlepage_product_descrptionbox_para"
                >
                  <p>
                    Minor differences in handmade embroidery and crafting are
                    expected due to human involvement, resulting in unique and
                    stunning artistic variations.
                  </p>
                </div>
              </Collapse>
            </Paper>
          </div>
        </div>
      </div>

      {/* recommand part */}
      <div>
        <div className="home_head1_part1_container">
          <div className="home_head1_part1 head_space">
            <p>You may also like</p>
          </div>
        </div>
        <div className="pro_home singlepage_product_recommand">
          {productlike
            .sort(() => 0.5 - Math.random())
            .slice(0, 6)
            .map((item, index) => (
              <div key={index}>
                <Link
                  to={
                    item.catalog
                      ? `/productsingle/${item.id}?sizes=${item.catalog}/viewpro.php`
                      : `/product/${item.id}`
                  }
                >
                  <div className="pro_part">
                    <div className="product_box_sliding_container_img">
                      <div className="home_product_box_image">
                        <img
                          src={
                            item.catalog
                              ? `${process.env.REACT_APP_API_IMAGE}${item.thumnail}`
                              : `${process.env.REACT_APP_API_IMAGE_SECOND}${item.thumnail}`
                          }
                          alt=""
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </div>
                    <div className="pro_name">
                      <p>{item.name}</p>
                    </div>
                    <div className="pro_price">
                      <p>Rs-{item.discount}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
      <div>{size && <Sizechart closeViewSize={closeViewSize} />}</div>
      <div>{color && <Color closeViewSize={closeViewSize} />}</div>
    </>
  );
};

export default Singlepage;
